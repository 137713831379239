import React from 'react';
import Loadable from '@loadable/component';
// Style Imports
import './styles.scss';
// SEO
import Seo from '@components/utility/SEO';
// Structural Imports
import Layout from '@components/structure/Layout.jsx';
// Content Imports
import Hero from '@components/content/hero/Hero.jsx';
import Image from '@components/content/fractals-image.jsx';
import DoctorDiscussionGuideCTA from '@components/content/DoctorDiscussionGuide/DoctorDiscussionGuide.jsx';
import { isMobile } from '@src/helper'
const Cta = Loadable(() => import('@components/content/cta/CTA'));
const KidneySmallIcon = Loadable(() => import('@components/content/icons/KidneySmall.jsx')
);
const PillIcon = Loadable(() => import('@components/content/icons/Pill.jsx'));
const ShieldIcon = Loadable(() => import('@components/content/icons/Shield.jsx'));
const TapeOneIcon = Loadable(() => import('@components/content/icons/TapeOne.jsx'));
const TapeTwoIcon = Loadable(() => import('@components/content/icons/TapeTwo.jsx'));
const YogaSmallIcon = Loadable(() => import('@components/content/icons/YogaSmall.jsx'));


const whatIsLupkynis = () => (
    <Layout addedClass='page--what-lupkynis'>
        <Seo
            title='What Is LUPKYNIS&reg; (voclosporin)?'
            description='LUPKYNIS is the first and only FDA-approved oral medication indicated for the treatment of lupus nephritis. See how LUPKYNIS works and download the doctor discussion guide. See full Safety and Prescribing Information, and BOXED WARNING.'
            canonicalURL='https://www.lupkynis.com/what-is-lupkynis'
        />
        <Hero addedClass='bg-coral hero--string'>
            <div className='col-wrapper'>
                <div className='container'>
                    <div className='hero__content'>
                        <h1 className='heading heading--accent'>What is Lupkynis?</h1>
                        <h2>A treatment <br className='mobile-only' /> that helps you <span>fight</span> back</h2>
                        <p>
                            LUPKYNIS is the first and only FDA-approved oral medication
                            specifically indicated for the treatment of lupus nephritis.
                            It has been proven in clinical trials to help control lupus
                            nephritis and protect the kidneys from further damage without
                            high-dose steroids.<sup>a</sup>
                        </p>
                    </div>
                    <div className='hero__image'>
                        <Image
                            data={{
                                desktopSrc: 'what-is-lupkynis/hero-desktop.png',
                                mobileSrc: 'what-is-lupkynis/hero-mobile.png',
                                altText: 'Image of Suriya, started treatment 2021',
                                loading: 'eager',
                            }}
                        />
                    </div>
                </div>
            </div>
            <div className='icon-bar col-wrapper bg-light-green text-center'>
                <div className='container'>
                    <div className='icon-bar__item'>
                        <div className='icon'>
                            <PillIcon />
                        </div>
                        <div className='icon-bar__content'>
                            <p>
                                An oral <br />
                                medication
                            </p>
                        </div>
                    </div>
                    <div className='icon-bar__item'>
                        <div className='icon'>
                            <YogaSmallIcon />
                        </div>
                        <div className='icon-bar__content'>
                            <p>
                                Helps to get lupus nephritis under control<sup>b</sup>
                            </p>
                        </div>
                    </div>
                    <div className='icon-bar__item'>
                        <div className='icon'>
                            <KidneySmallIcon />
                        </div>
                        <div className='icon-bar__content'>
                            <p>
                                Helps stop the attack of <span className='nowrap'>lupus nephritis</span>
                            </p>
                        </div>
                    </div>
                    <div className='icon-bar__item'>
                        <div className='icon'>
                            <ShieldIcon />
                        </div>
                        <div className='icon-bar__content'>
                            <p>Helps protect the kidneys from further damage</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='footnote text-center'>
                <p>
                    <sup>a</sup>As part of a treatment plan that included mycophenolate
                    mofetil and steroids.
                </p>
                <p>
                    <sup>b</sup>Control was defined as reducing protein in the urine to
                    &le;0.5&nbsp;mg/mg, while helping maintain kidney function.
                </p>
            </div>
            <div className='text-center'>
                <Cta url='/why-lupkynis'>
                    Learn about the results from clinical trials for LUPKYNIS
                </Cta>
            </div>
        </Hero>
        <section id='HowDoesLupkynisWork' className='col-wrapper bg-white'>
            <div className='container'>
                <div className='copy-block text-center'>
                    <div className='how-does-title'>
                        <h4>How does lupkynis work?</h4>
                    </div>
                    <p>
                        With lupus nephritis, certain cells of your immune system are
                        activated when they normally would not be, and they attack the
                        kidneys. LUPKYNIS works to counter that attack by calming the
                        immune system. It helps reduce inflammation and protect the
                        kidneys from further permanent damage without the use of high-dose
                        steroids.
                    </p>
                </div>
                <Image
                    data={{
                        desktopSrc: 'what-is-lupkynis/improvement-desktop.png',
                        mobileSrc: 'what-is-lupkynis/improvement-mobile.png',
                        altText: '"After I started taking LUPKYNIS, my first set of test results, including blood work and a urinalysis, showed improvement" -  Jennifer, started treatment in 2021',
                        loading: 'eager',
                    }}
                />
            </div>
        </section>

        <section id='OneTwoPunch' className='bg-white text-center'>
            <div className='container'>
                <div className='icon-callout'>
                    <div className='icon desktop-only'></div>
                    <div className='copy-block'>
                        <h3 className='heading heading--subhead'>LUPKYNIS packs a powerful <span className='yellow-bg'>one&#8209;two punch</span></h3>
                    </div>
                </div>
                <div className='col-wrapper'>
                    <div className='container'>
                        <div className='lupkynis-one-two-punch'>
                            <div className='icon-callout icon-callout--strong'>
                                <div className='icon'>
                                    <TapeOneIcon />
                                </div>
                                <div>
                                    <p>
                                        <span className='sr-only'>One:</span> Helps stop
                                        the attack on your kidneys
                                    </p>
                                </div>
                            </div>
                            <div>
                                <p>
                                    LUPKYNIS helps calm the immune system, reducing
                                    inflammation in the kidneys and helping stop the
                                    attack of lupus nephritis.
                                </p>
                            </div>
                        </div>
                        <div className='lupkynis-one-two-punch'>
                            <div className='icon-callout icon-callout--strong'>
                                <div className='icon'>
                                    <TapeTwoIcon />
                                </div>
                                <div>
                                    <p>
                                        <span className='sr-only'>Two:</span> Helps
                                        protect your kidneys from further damage
                                    </p>
                                </div>
                            </div>
                            <div>
                                <p>
                                    LUPKYNIS protects specific cells of the kidneys to
                                    help keep them functioning. It works by helping stop
                                    protein from leaking into the urine, which is a key
                                    sign of disease activity.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <Cta url='/why-lupkynis'>See how it works</Cta>
            </div>
        </section>
        <DoctorDiscussionGuideCTA />
    </Layout>
);

export default whatIsLupkynis;
